html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #fff;
  min-height: 100vh;
  background: linear-gradient(135deg, #000, #1c1c1c);
  font-family: Montserrat, sans-serif;
  line-height: 2.4rem;
}

html {
  font-size: 62.5%;
}

p {
  max-width: 600px;
  text-align: justify;
  margin: 1.6rem auto;
  font-size: 1.6rem;
}

.content__container {
  max-width: calc(100% - 32px);
  margin: 0 auto;
  padding-top: 2.4rem;
}

@media (min-width: 768px) {
  .content__container {
    margin: 0 auto;
    padding-top: 4.8rem;
  }
}

.content__headshot {
  width: 100%;
  max-width: 300px;
  border-radius: 50%;
  margin: 0 auto 2.4rem;
  display: block;
}

.content__title, .content__name {
  text-align: center;
}

@media (min-width: 768px) {
  .content__name {
    font-size: 2.4rem;
  }
}

@media (min-width: 768px) {
  .content__title {
    font-size: 2rem;
  }
}

.content__divider {
  height: 1px;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.content__divider:before {
  content: "";
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #fff, rgba(0, 0, 0, 0));
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
}

.content__description a, .content__description a:hover, .content__description a:visited, .content__description a:focus, .content__description a:active {
  color: #969696;
}

/*# sourceMappingURL=index.b92db3a9.css.map */
